// 1. Import `extendTheme`
import { theme } from '@chakra-ui/pro-theme'
import { extendTheme } from '@chakra-ui/react'

import '@fontsource-variable/inter'

const proTheme = extendTheme(theme)
const extenstion = {
  colors: {
    ...proTheme.colors,
    brand:
    {
      50: '#daf8ff',
      100: '#aee4ff',
      200: '#7fd0fc',
      300: '#4fbef9',
      400: '#22abf6',
      500: '#0992dd',
      600: '#0071ad',
      700: '#00517d',
      800: '#00324e',
      900: '#001120',
    },
    primary: {
      50: '#e0f5ff',
      100: '#b3e0ff',
      200: '#80ccff',
      300: '#4db8ff',
      400: '#26a5ff',
      500: '#0c8bff',
      600: '#006bba',
      700: '#004b8a',
      800: '#002c5b',
      900: '#00102d',
    },
    secondary: {
      50: '#f1f1f1',
      100: '#dcdcdc',
      200: '#c4c4c4',
      300: '#adadad',
      400: '#999999',
      500: '#808080',
      600: '#666666',
      700: '#4d4d4d',
      800: '#333333',
      900: '#1a1a1a',
    },
    success: {
      50: '#f0fdf4',
      100: '#dcfce7',
      200: '#bbf7d0',
      300: '#86efac',
      400: '#4ade80',
      500: '#22c55e',
      600: '#16a34a',
      700: '#047857',
      800: '#005c4f',
      900: '#004249',
    },
    danger: {
      50: '#fff2f2',
      100: '#ffd6d9',
      200: '#ffa8b4',
      300: '#ff708d',
      400: '#ff3d71',
      500: '#e31a5f',
      600: '#c00f4d',
      700: '#9d0040',
      800: '#750035',
      900: '#58002b',
    },
    warning: {
      50: '#fffaf0',
      100: '#feebc8',
      200: '#fbd38d',
      300: '#f6ad55',
      400: '#ed8936',
      500: '#dd6b20',
      600: '#c05621',
      700: '#9c4221',
      800: '#7b341e',
      900: '#652b19',
    },
    info: {
      50: '#f0f9ff',
      100: '#c2e2ff',
      200: '#94cbff',
      300: '#63b3ff',
      400: '#3e8bff',
      500: '#1f6eb6',
      600: '#125c8c',
      700: '#0a4c66',
      800: '#083952',
      900: '#052838',
    },
    light: {
      50: '#f8f9fa',
      100: '#e9ecef',
      200: '#dee2e6',
      300: '#ced4da',
      400: '#adb5bd',
      500: '#868e96',
      600: '#495057',
      700: '#343a40',
      800: '#212529',
      900: '#121416',
    },
    dark: {
      50: '#f8f9fa',
      100: '#e9ecef',
      200: '#dee2e6',
      300: '#ced4da',
      400: '#adb5bd',
      500: '#868e96',
      600: '#495057',
      700: '#343a40',
      800: '#212529',
      900: '#121416',
    },
    black: '#000000',
    white: '#ffffff',
    
  },
  fonts: {
    heading: "'Inter Variable', -apple-system, system-ui, sans-serif",
    body: "'Inter Variable', -apple-system, system-ui, sans-serif",



  },
 
 

 
  
}
export const themeUPM = extendTheme(extenstion, proTheme)