import { Box, Center, Container, Heading } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import {
    SimpleGrid,
    Image,
    Flex,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
  } from '@chakra-ui/react'

  import { IoAnalyticsSharp, IoLogoBitcoin, IoSearchSharp } from 'react-icons/io5'

  import WorkstationImage from '@images/workstation.svg'
import { GrDocument } from "react-icons/gr";

const WorkstationPage = () => {
    return (<Box minH={'100vh'}>

        <Helmet>
            <title>Workstation</title>
            <meta name="description" content="Workstation Page" />
            <meta name="keywords" content="Workstation, Workstation Page" />
            <meta name="author" content="UPM" />
        </Helmet>

        <Center >
            <Heading as="h1" size="lg" color="gray.700" textAlign="center" mt={10}>
                Workstations
            </Heading>
        </Center>
        <Container maxW={'5xl'} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={'uppercase'}
            color={'blue.400'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('blue.50', 'blue.900')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}>
            Our Story
          </Text>
          <Heading fontSize={'larger'} as={'h6'}>A digital Product design agency</Heading>
          <Text color={'gray.500'} fontSize={'lg'}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
            eirmod tempor invidunt ut labore
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider borderColor={useColorModeValue('gray.100', 'gray.700')} />
            }>
            <Feature
              icon={<Icon as={GrDocument} color={'gray.500'} w={5} h={5} />}
              iconBg={useColorModeValue('green.100', 'green.900')}
              text={'Current Job'}
            />
            <Feature
              icon={<Icon as={IoAnalyticsSharp} color={'gray.500'} w={5} h={5} />}
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text={`T 5:15:00`}
            />
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded={'md'}
            alt={'feature image'}
            src={
              WorkstationImage
            }
            objectFit={'cover'}
          />
        </Flex>
      </SimpleGrid>
    </Container>
    </Box>)
}

const Feature = ({ text, icon, iconBg }) => {
    return (
      <Stack direction={'row'} align={'center'}>
        <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    )
  }

  export default WorkstationPage;