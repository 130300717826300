


import ScrollToTop from '@components/ScrollToTop';


import { ToastContainer, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APPRoute } from './APPRoutes';
import { Suspense, lazy } from 'react';
import GeneralSpinner from '@components/GeneralSpinner';
import { HelmetProvider } from 'react-helmet-async';
const MainFooter = lazy(() => import('@components/MainFooter'))

function App() {
    return (<HelmetProvider>

        <ScrollToTop />
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='dark'
        />
        <APPRoute />

        <Suspense fallback={<GeneralSpinner />}>
            <MainFooter />
        </Suspense>
    </HelmetProvider>)

}

export default App;
